<template>
  <div class="search-block">
    <div class="row">
      <label class="input-block col-xl-9 col-md-9 col-sm-12">
          <input type="text"  v-model="form.search"><span/>
          <span class="input-block__name" :class="form.search.length ?'active' : null" >Enter NDC or Drug namber</span>
      </label>
      <div class="col-md-3 col-sm-12">
        <div class="d-flex justify-end">
          <button class="btn grey" @click="searchOrders()">Search</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data(){
    return{
      form:{
        search:'',
      }
    }
  },
  methods:{
    searchOrders(){
      this.getWishlist({search_param: this.form.search})
    },
    ...mapActions({
      getWishlist:'general/getWishlist'
    })
  },
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.search-block{
  width: 100%;
  padding: 40px 100px;
  border-radius: 27px;
  background-color: $white-grey;
}
</style>
